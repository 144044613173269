.imageWrapper {
  @apply w-full mb-5 overflow-hidden rounded-lg;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.image {
  @apply rounded-lg transition ease-in-out duration-[2s] hover:scale-125;
}

.placeholder {
  @apply w-full h-96 bg-stone-800 animate-pulse opacity-80;
}
