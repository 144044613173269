.slides {
  @apply w-screen h-screen z-20 overflow-hidden;
}

.slide,
.slideContainer {
  @apply w-screen h-screen absolute top-0 left-0 z-20 bg-cover bg-center overflow-hidden;
  transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1);
  will-change: transform;
}

.active > .slideContainer {
  transform: translateY(100vh);
}

.active > .slideContainer > .slide {
  transform: translateY(-100vh);
}

.slideContainer:nth-child(1),
.slideContainer:nth-child(1) > .slide {
  transition-delay: 1.2s;
}
.slideContainer:nth-child(2),
.slideContainer:nth-child(2) > .slide {
  transition-delay: 1s;
}
.slideContainer:nth-child(3),
.slideContainer:nth-child(3) > .slide {
  transition-delay: 0.8s;
}
.slideContainer:nth-child(4),
.slideContainer:nth-child(4) > .slide {
  transition-delay: 0.6s;
}
.slideContainer:nth-child(5),
.slideContainer:nth-child(5) > .slide {
  transition-delay: 0.4s;
}
.slideContainer:nth-child(6),
.slideContainer:nth-child(6) > .slide {
  transition-delay: 0.2s;
}
