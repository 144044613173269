.logo {
  @apply flex items-center justify-center w-72 h-72 border-2 rounded-full border-pink-200 bg-black;
}

.letter {
  @apply font-serif text-9xl bg-gradient-to-br from-pink-200 to-pink-400 text-transparent bg-clip-text;
}

.spacer {
  @apply bg-black;
  transform-origin: center;
  transform: rotate(20deg);
  z-index: 1;
}
