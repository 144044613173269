.hero {
  @apply relative w-full overflow-hidden rounded-lg h-48 sm:h-96 xl:h-[600px] cursor-pointer;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.hero .video {
  @apply absolute w-screen h-screen;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero .banner {
  @apply absolute top-0 left-0 flex flex-col w-full h-full justify-center p-10 lg:p-0 ml-0 lg:ml-32 items-start;
}

.hero .banner .title {
  @apply text-white text-xl sm:text-6xl font-serif mb-4 drop-shadow-md;
}

.hero .banner .content {
  @apply text-white text-lg sm:text-4xl font-sans w-full lg:w-1/3;
}
