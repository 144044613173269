.masonry {
  @apply sm:columns-1 md:columns-2 lg:columns-3 xl:columns-4 gap-5 cursor-pointer;
}

.masonry .masonryItem {
  @apply w-full mb-5 overflow-hidden rounded-lg;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.masonry .masonryItem .image {
  @apply rounded-lg transition ease-in-out duration-[2s] hover:scale-125;
}
